import * as React from 'react'
import {
	DataBindingProvider,
	EntityListSubTree,
	EntitySubTree,
	FeedbackRenderer,
	Field,
	FieldView,
	GenericPage,
	HasOne,
	useRedirect,
} from '@contember/admin'
import { imageResizingUrl } from '../../utils/images'
import { ViewTitleBar } from '../../components/partials/ViewTitleBar'
import { CURRENT_VINTAGE_CREATED_AFTER } from '../../utils/constant'

export default () => {
	const redirect = useRedirect()
	const urlParams = new URLSearchParams(window.location.search)
	if (!urlParams.get('id')) {
		redirect('view/list')
	}

	return (
		<>
			<ViewTitleBar />
			<GenericPage>
				<DataBindingProvider stateComponent={FeedbackRenderer}>
					<EntitySubTree entity="Organization(id=$id)">
						<h1 className="text-center pt-8">
							<Field field="name" />
						</h1>
					</EntitySubTree>
					<div>
						<div className="organizations-list">
							<EntityListSubTree entities={`Team[organization.id=$id && isApproved=true && createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}' && isDeleted=false]`} orderBy="stats.totalStandardizedPointsWithAccompanyingActivities desc">
								<article className="team-list-item">
									<HasOne field="coverImage">
										<FieldView
											fields={['url', 'width', 'height', 'alt']}
											render={(url, width, height, alt) => {
												const urlValue = url.getAccessor().value

												return (
													<header>
														{urlValue ? (
															<img
																src={imageResizingUrl(String(urlValue))}
																alt={
																	alt.getAccessor().value
																		? String(alt.getAccessor().value)
																		: ''
																}
																width={
																	width.getAccessor().value
																		? String(width.getAccessor().value)
																		: undefined
																}
																height={
																	height.getAccessor().value
																		? String(height.getAccessor().value)
																		: undefined
																}
															/>
														) : (
															<span className={`img-color-replacement bg-amber-400`} />
														)}
													</header>
												)
											}}
										/>
									</HasOne>
									<section>
										<FieldView field="name" render={field => {
											const value = field.getAccessor().value
											return (
												<h2 className={typeof value === 'string' && value.length > 5 ? '!text-2xl' : '!text-4xl'}>
													{value ? `${value}` : 'Bez jména'}
												</h2>
											)
										}} />
										<HasOne field="stats">
											<FieldView
												field="totalStandardizedPointsWithAccompanyingActivities"
												render={field => {
													const currentDate = new Date()
													const monday18September8AM = new Date('September 18, 2023 08:00:00')
													if (currentDate < monday18September8AM) {
														return <p className="text-xl">0 bodů</p>
													}
													const value = field.getAccessor().value
													return <p className="text-xl">{value ? `${Math.round(Number(value))} bodů` : '0 bodů'}</p>
												}}
											/>
										</HasOne>
									</section>
									<footer>

									</footer>
								</article>
							</EntityListSubTree>
						</div>
					</div>
				</DataBindingProvider>
			</GenericPage>
		</>
	)
}
